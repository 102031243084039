window.realjQuery = jQuery;

jQuery(function ($) {

    $('.openerreichbarkeit').hover(function (event) {
        event.preventDefault();
        $('.erreichbarkeitfoldout').addClass("hover");
    }, function (event) {
        event.preventDefault();
        setTimeout(function() {$('.erreichbarkeitfoldout').removeClass("hover");},2000);
    })

    $('.openerreichbarkeit2').hover(function (event) {
        $('.erreichbarkeitfoldout2').addClass("hover");
    }, function (event) {
        setTimeout(function() {$('.erreichbarkeitfoldout2').removeClass("hover");},2000);
    })

    $('.openerreichbarkeit3').hover(function (event) {
        $('.erreichbarkeitfoldout3').addClass("hover");
    }, function (event) {
        setTimeout(function() {$('.erreichbarkeitfoldout3').removeClass("hover");},2000);
    })
});