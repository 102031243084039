window.realjQuery = jQuery;

jQuery(function ($) {
    $(".navbar-toggler").on("click", function(){
        event.preventDefault();
        $('.hauptnavigationmobil').toggleClass("show");
    });

    var menu = $(".hauptnavigation");
    var items = $("li",menu);
    items.each(function(){
        var item = $(this);
        if(!item.children(".sub-menu").length) return;
        var pfeil = $("<span class='navarrowmobil'><i class='fa fa-angle-down'></i></span>");
        item.append(pfeil);
        pfeil.on("click",function(){
            $("i",pfeil).removeClass("fa-angle-up");
            $("i",pfeil).addClass("fa-angle-down");

            if(!item.hasClass("hover")){
                var foldout = true;
            }

            items.removeClass("hover");
            if(foldout) {
                item.addClass("hover");
                $("i",pfeil).removeClass("fa-angle-down");
                $("i",pfeil).addClass("fa-angle-up");
            }

        });
    });
});