window.realjQuery = jQuery;

jQuery(function ($) {

    $('.fullpage table').addClass("table");

    var hoverboxes = $('.vc-hoverbox');
    hoverboxes.on('click',function(){
        hoverboxes.removeClass('hover');
        $(this).addClass('hover');
    })

    var container = $("<div class='slidemeinner container'>");
    var teaser = $(".slideme .leistungenteaser .wpb_column");
    container.append(teaser);
    $(".slideme").append(container);
    container.slick({
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 3,
        adaptiveHeight: false,
        nextArrow: '<button class="slick-next slick-arrow" aria-label="Next" type="button" style=""><i class="fa fa-angle-right"></i></button>',
        prevArrow: '<button class="slick-prev slick-arrow" aria-label="Previous" type="button" style=""><i class="fa fa-angle-left"></i></button>',
        responsive: [
            {
                breakpoint: 770,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1
                }
            }
        ]
    });
    $(".slideme .wpb_wrapper .leistungenteaser").remove();

    // vCARD erstellen?!
    $('.vcard').each(function() {
        var name = ($(this).find('.fn').text());
        var title = $(this).find('.jobtitle').text();
        var tel = $(this).find('.tel').text();
        var mobil = $(this).find('.mobil').text();
        var email = $(this).find('.email').text();

        var vcard = ['BEGIN:VCARD',
            'FN;CHARSET=utf-8:' + name,
            'ROLE;CHARSET=utf-8: ' + title,
            'TITLE;CHARSET=utf-8: ' + title,
            'TEL;TYPE=WORK,VOICE: ' + tel,
            'TEL;TYPE=CELL,VOICE: ' + mobil,
            'EMAIL: ' + email,
            'END:VCARD'].join('\n');

        var button = $('<button class="btn btn-primary">Kontakt speichern</button>');
        $(this).append(button);

        button.click(function() {
            downloadToFile2(vcard, 'vcard.vcf', 'text/vcard; charset=iso-8859-1');
        });

    })

    function toIso(t) {
        return decodeURIComponent(escape(t));
    }

    function downloadToFile2(content, filename, contentType) {
        const a = document.createElement('a');
        const file = new Blob([content], { type: contentType });

        a.href = URL.createObjectURL(file);
        a.download = filename;
        a.click();

        URL.revokeObjectURL(a.href);
    }

    function downloadToFile(content, filename, contentType, button) {
        var file = new Blob([content], { type: contentType });
        button.href = URL.createObjectURL(file);
        console.log(file);
        button.download = filename;
    }

});